﻿const getDisclaimerTextTranslated = (lang) => {

    switch (lang) {
        case 'Es':
            return 'El Asistente Virtual OC utiliza tecnología de IA generativa en sus respuestas. Para más información, visite'
        case 'Fr':
            return 'L\'Assistant Virtuel OC utilise la technologie d\'IA générative dans ses réponses. Pour plus d\'informations, veuillez visiter';
        default:
            return 'The OC Virtual Assistant uses generative AI technology in its responses. For more information, please visit';

    }
}

const getFooterTranslated = (lang) => {
    switch (lang) {
        case 'Es':
            return ['Privacidad', 'Accesibilidad'];
        case 'Fr':
            return ['Confidentialité', 'Accessibilité'];
        default:
            return ['Privacy', 'Accessibility'];

    }
}

const getFeedbackPromptTranslated = (lang) => {
    switch (lang) {
        case 'Es':
            return '¿Esto respondió a tu pregunta?';
        case 'Fr':
            return 'Est-ce que cela a répondu à votre question ?';
        default:
            return 'Did this answer your question?';

    }
}

const getFbCommentTranslated = (lang) => {
    switch (lang) {
        case 'Es':
            return ['¿Qué hice bien?', '¿Qué hice mal?', 'Ingresa tu comentario aquí'];
        case 'Fr':
            return ['Qu\'est-ce que j\'ai bien fait ?', 'Qu\'est-ce que j\'ai mal fait ?', 'Entrez votre commentaire ici'];
        default:
            return ['What did I get right?', 'What did I get wrong?', 'Enter your comment here'];

    }
}

const getConfirmationTranslated = (lang) => {
    switch (lang) {
        case 'Es':
            return ['¡Gracias por tu calificación!', '¡Gracias! Tus comentarios han sido enviados.'];
        case 'Fr':
            return ['Merci pour votre évaluation !', 'Merci pour vos commentaires ! Ils ont été soumis.'];
        default:
            return ['Thanks for your rating!', 'Thank you for your feedback! It has been submitted.'];

    }
}
const getLangLabelsTranslated = (lang) => {
    switch (lang) {
        case 'Es':
            return ['Ver todos los idiomas', 'El Asistente Virtual de OC puede responder en cualquiera de los siguientes idiomas. Si bien no todos los elementos se traducirán, estamos trabajando para admitir completamente los idiomas de esta lista'];
        case 'Fr':
            return ['Voir toutes les langues', 'L’assistant virtuel OC est en mesure de répondre dans l’une des langues ci-dessous. Bien que tous les éléments ne soient pas traduits, nous nous efforçons de prendre pleinement en charge les langues de cette liste'];
        default:
            return ['See all languages', 'The OC Virtual Assistant is able to respond in any of languages below. While not all elements will be translated we are working to fully support the languages in this list'];

    }
}

const getPlaceHolderTranslated = (lang) => {
    switch (lang) {
        case 'Es':
            return 'Ingresa tu mensaje';
        case 'Fr':
            return 'Saisissez votre message';
        default:
            return 'Input your message'
    }
}


export { getDisclaimerTextTranslated, getFooterTranslated, getFeedbackPromptTranslated, getFbCommentTranslated, getConfirmationTranslated, getLangLabelsTranslated, getPlaceHolderTranslated }