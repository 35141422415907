﻿//const ignoreMessagesList = ["Hello there! I'm The OC Virtual Assistant. How can I help you today?","_For the best experience, please use short and clear sentences. I_’_m still learning, so your patience and feedback are really appreciated. They help me get better and make our future conversations even better._","Thank you. Please explain further, we value your feedback.","Thank you. It has been reported!","I can help you scheduling an appointment. Please select the location","I understand.\nWould you like to make an appointment with a counselor?","Looks like you need to speak to one of our team directly.\n\nPlease select the department you want to communicate with.","I'm sorry, I'm not sure how to help with that. Can you try rephrasing?","You’re welcome.\nIf you require further assistance feel free to ask!","Bye now. Have a nice day!","Are you a Vernon Campus student?","Hello, how can I help you today?","To clarify, did you mean:","How would you like to reset your password?","Are you a Domestic or International student?"
//];

const cleanString = (str) => {
    if (str === undefined) {
        return;
    }
    return str
        .replace(/’/g, "'") 
        .replace(/�/g, "") 
        .trim();
};

export const shouldIgnore = (msg, isUserMsg) => {
    //console.log(`Is user message? ${isUserMsg}`);
    let ignoreIt;
    if (isUserMsg) {
      ignoreIt =  process.env.REACT_APP_IGNORE_ANSWER_LIST.split(";").some(m => {
            // console.log(`Current message: ${cleanString(m)} \n, is equal to ${cleanString(msg)} ?\n ${cleanString(m) === cleanString(msg)}`);
            return msg === undefined ? true : cleanString(m) === cleanString(msg);

        });
    } else {
      ignoreIt =  process.env.REACT_APP_IGNORE_MESSAGE_LIST.split(";").some(m => {
             //console.log(`Current message: ${cleanString(m)} \n, is equal to ${cleanString(msg)} ?\n ${cleanString(m) === cleanString(msg)}`);
            return msg === undefined ? true : cleanString(m) === cleanString(msg);

        });
    }
    return ignoreIt;
}

