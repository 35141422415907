import { getPlaceHolderTranslated } from './languages/translateUI';

const changePlaceholder = (lang) => {
    const sendbox = document.getElementsByClassName('webchat__send-box-text-box__input')[0];
    if (sendbox) {
        sendbox.placeholder = getPlaceHolderTranslated(lang
        );
    } 
}
const disableSendbox = () => {
    //const sendbox = document.getElementsByClassName('webchat__send-box-text-box__input')[0];
    //if (sendbox) {
    //    sendbox.disabled = true;
    //    sendbox.placeholder = "";
       const sendboxContainer = document.getElementsByClassName('webchat__send-box__main')[0];
        //const sendIcon = document.getElementsByClassName('webchat__icon-button')[0];
        if (sendboxContainer) {
            sendboxContainer.style.display = "none";
            //sendboxContainer.style.backgroundColor = "#f0f0f0";
            //sendboxContainer.style.color = "#a0a0a0";
            //sendboxContainer.style.cursor = "not-allowed";    
        }
        //if (sendIcon) {
        //    sendIcon.style.display = "none";
        //}
       
    //} else {
    //    console.log('No sendbox yet');
    //}
};
const enableSendbox = () => {
    //const sendbox = document.getElementsByClassName('webchat__send-box-text-box__input')[0];
    //if (sendbox && sendbox.disabled) {
    //    sendbox.disabled = false;
    //    sendbox.placeholder = 'Input your message';
        const sendboxContainer = document.getElementsByClassName('webchat__send-box__main')[0];
        if (sendboxContainer) {
            //sendboxContainer.style.backgroundColor = "";
            //sendboxContainer.style.color = "";
            //sendboxContainer.style.cursor = "";
            sendboxContainer.style.display = "";
        }
    //} else {
    //    console.log('No sendbox');
    //}
   
}

export { disableSendbox, enableSendbox, changePlaceholder };