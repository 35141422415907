// Imports from Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowMinimize, faExchangeAlt } from '@fortawesome/free-solid-svg-icons';

export const ChatbotHeader = ({ switchChatBoxSide, minimizeChatBox }) => {
    return (
        <header>
            <span className="branding">The OC Virtual Assistant</span>
            <div className="filler" />

            <button aria-label="Switch Side" className="switch" title="Switch" onClick={switchChatBoxSide}>
                <FontAwesomeIcon icon={faExchangeAlt} />
            </button>
            <button aria-label="Minimize Chat Screen" className="minimize" title="Minimize" onClick={minimizeChatBox}>
                <FontAwesomeIcon icon={faWindowMinimize} />
            </button>
        </header>
    );
}