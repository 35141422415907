import { LangSelector } from '../Language_Selection/langSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
export const Toolbar = ({ chatLanguage, updateLanguage, resetConversation }) => {
    return (
        <div className="tools-container">
            <LangSelector chatLanguage={chatLanguage} updateLanguage={updateLanguage} />
            <button aria-label="Reset Conversation" title="Reset" className="reset" onClick={resetConversation}>
                <FontAwesomeIcon icon={faSync} />
            </button>
        </div>
    );
}