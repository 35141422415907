import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { getDisclaimerTextTranslated } from '../../services/languages/translateUI';
import '../../assets/css/style.scss'

export const Disclaimer = ({ showDisclaimer, closeDisclaimer, chatLanguage }) => {
    const disclaimerText = getDisclaimerTextTranslated(chatLanguage);
    return (
        (showDisclaimer &&
            (
            <div className="disclaimer-container">
                <p className="disclaimer-text" > {disclaimerText} <a aria-label="Link to The OC Virtual Asistant page" href="https://www.okanagan.bc.ca/ocvirtualassistant" target="_blank"><strong>OC-Virtual-Assistant</strong></a>
                    </p>
                    <button onClick={closeDisclaimer}>
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                </div>
            )
        )
    );
}