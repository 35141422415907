import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsDown, faThumbsUp, faPaperPlane, faXmark } from '@fortawesome/free-solid-svg-icons';
import { getFbCommentTranslated } from '../../services/languages/translateUI';
import '../../assets/css/style.scss';

const GatherFeedback = ({ sendUserFeedback, isGoodFeedback, chatLanguage }) => {
    const [userFeedback, setUserFeedback] = useState('');
    const handleChange = (comment) => {
        setUserFeedback(comment.target.value)
    }
    const handleSubmit = (comment) => {
        comment.preventDefault();
        //console.log(userFeedback);
        sendUserFeedback(userFeedback);
    }
    const commentUIText = getFbCommentTranslated(chatLanguage);
    return (
        <form onSubmit={handleSubmit} className="user-feedback">
            <div className="feedback-header">
                {isGoodFeedback[1] ?
                    <div className="feedback-header-content">
                        <FontAwesomeIcon icon={faThumbsUp} />
                        <div className="separator"></div>
                        <span>{commentUIText[0]}</span>
                    </div>
                    :
                    <div className="feedback-header-content">
                        <span><FontAwesomeIcon icon={faThumbsDown} /></span>
                        <div className="separator"></div>
                        <span>{commentUIText[1]}</span>
                    </div>
                }
                <button className="send-comment"> {userFeedback === '' ?
                    <div>
                        < FontAwesomeIcon icon={faXmark} className="skip-btn" />
                    </div> :
                    <div>
                        < FontAwesomeIcon icon={faPaperPlane} />
                    </div>
                } </button>
            </div>
            <textarea
                className='user-comment'
                maxLength='1000'
                name='userFeedback'
                value={userFeedback}
                placeholder={commentUIText[2]}
                aria-label='Enter your comment here'
                onChange={handleChange} >
            </textarea>


        </form>
    );
};

export default GatherFeedback;