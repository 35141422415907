import {getFooterTranslated } from '../../services/languages/translateUI'
import '../../assets/css/style.scss';
export const ChatbotFooter = ({ chatLanguage }) => {
    const footerText = getFooterTranslated(chatLanguage);
    return (
        <footer >
            <div className="filler" />
            <span className="policies">
                <a aria-label="Link to The OC Privacy Policy page" href="https://www.okanagan.bc.ca/sites/default/files/2023-04/Privacy_Policy.pdf" target="_blank">{footerText[0]}</a> | <a aria-label="Link to The OC Accesibility Policy page" target="_blank" href="https://www.okanagan.bc.ca/web-accessibility-and-usability">{footerText[1]}</a>
            </span>
        </footer>
    );
}