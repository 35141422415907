import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaceSmileBeam, faFaceSmileWink } from '@fortawesome/free-solid-svg-icons';
import { getConfirmationTranslated } from '../../services/languages/translateUI'
import '../../assets/css/style.scss';

const ConfirmationMsg = ({ userFeedback, chatLanguage }) => {
    const userSkipped = (userFeedback === '' || userFeedback === undefined) ? true : false;
    const confirmationText = getConfirmationTranslated(chatLanguage);
    let msg = userSkipped ? confirmationText[0] : confirmationText[1];

    return (
        <div className="confirmation-feedback">
            {userSkipped === '' ?
                <FontAwesomeIcon icon={faFaceSmileWink} />
                :
                <FontAwesomeIcon icon={faFaceSmileBeam} />
            }

            <div className={userSkipped ? "separator" : "separator-dynamic"}></div>
            <span className="confirmation-text">{msg}</span>
        </div>
    );
};

export default ConfirmationMsg;