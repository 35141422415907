import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './services/serviceWorker';

const widgetDivs = document.querySelectorAll('.chatbot-widget');

widgetDivs.forEach(div => {
    ReactDOM.render(
        <React.StrictMode>
            <App source={div.dataset.source} />
        </React.StrictMode>,
        div
    );
});


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
