// code taken from: https://medium.com/@krutilin.sergey.ks/fixing-the-safari-mobile-resizing-bug-a-developers-guide-6568f933cde0
document.documentElement.classList.add("is-safari");
//TODO: Add a case for when the browser is google chrome and device is mobile because there is a minor offset that needs to be taken into account
const IS_TOUCH_SUPPORTED = false;
const IS_STICKY_INPUT_BUGGED = true;

// Boolean for determining if there is an overlay (I THINK NOT IMPORTANT FOR OUR PURPOSES)
const overlayCounter = {
    isOverlayActive: false
};

const windowRef = window.visualViewport || window; // We grab the window element to get the visual viewport reference
let setViewportVH = true; // Determines if the focus is on the resizing element (I THINK NOT IMPORTANT FOR OUR PURPOSES)
let lastVH; // Sets a property for the previous height to be a number (I THINK NOT IMPORTANT FOR OUR PURPOSES)

export const setVH = () => {
    let vh = (setViewportVH && !overlayCounter.isOverlayActive ? windowRef.innerHeight || windowRef.height : window.innerHeight) * 0.01;

    vh = +vh.toFixed(2);
    //console.log(`LastVH: ${lastVH}. New VH: ${vh}`);
    //if (lastVH === vh) {
    //    return;
    //}
    //lastVH = vh;

    let chatBoxRef = document.getElementsByClassName("chat-box")[0];
    if (chatBoxRef) {
        chatBoxRef.style.setProperty("--vh", `${vh}px`);
       // console.log('set vh property');
    } else {
       // console.log('Waiting for element to mount... Patience is a virtue');
    }
};

// Add resize event listener on page load
window.addEventListener("resize", setVH);
setVH();

if (IS_STICKY_INPUT_BUGGED) {
   // console.log("DEBUG: StickyInput");

    const toggleResizeMode = () => {
        setViewportVH = IS_STICKY_INPUT_BUGGED && !overlayCounter.isOverlayActive;

        setVH();
        if (windowRef !== window) {
            if (setViewportVH) {
                window.removeEventListener("resize", setVH);
                windowRef.addEventListener("resize", setVH);
            } else {
                windowRef.removeEventListener("resize", setVH);
                window.addEventListener("resize", setVH);
            }
        }
    };

    toggleResizeMode();
}
