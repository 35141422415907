/**Project based on the Minimizable web chat UI template https://github.com/microsoft/BotFramework-WebChat/tree/master/samples/06.recomposing-ui/a.minimizable-web-chat
 * 
 * This is a wrapper for the WebChat component that allows it to:
 *  -Minimize/Maximize
 *  -Switch sides (Left-right)
 *  -Reset conversation
 *  -Alert new message status (Subtle red dot)
 *  -Alert bot connection status (Message icon filled/empty)
 *  -Display reaction buttons (Thumbs up/down) that sends event to copilot to send feedback
 * 
 * This chat interface is connected to the Production Student Chabot in Copilot Studios: Felix
 * 
 * Most of the Icons are from FontAwesome, however there are a few Microsoft icons. The License for this template, including the icons is included in the project solution
 */

// Imports from REACT
import classNames from 'classnames';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import ReactDOM, { createPortal } from 'react-dom';
// Imports from Bot Framework
import { createStore, createStyleSet } from 'botframework-webchat';
import { disconnect } from 'botframework-webchat-core';
// Custom React components
import WebChat from './WebChat';
import Feedback from './Feedback/FeedbackPrompt';
import GatherFeedback from './Feedback/GatherFeedback';
import ConfirmationMsg from './Feedback/ConfirmationMsg';
import { Disclaimer } from './Chatbot_Sections/disclaimer';
import { ChatbotFooter } from './Chatbot_Sections/chatbotFooter';
import { Toolbar } from './Chatbot_Sections/toolbar';
import { ChatbotHeader } from './Chatbot_Sections/chatbotHeader'
//Services Imports
import { setVH } from '../services/ResizeChatBox';
import { shouldIgnore } from '../services/ignoreService';
import { enableSendbox, disableSendbox, changePlaceholder } from '../services/sendboxManipulation'
/*import styleSendBox from '../services/forcedStyles'*/
// CSS Styling
import '../assets/css/fabric-icons-inline.css';
import '../assets/css/style.scss';

import updateIn from 'simple-update-in';





const MinimizableWebChat = (props) => {
    /*
Function to get the connection to our Copilot bot token.
Sets the State variable: token
*/
    const handleFetchToken = useCallback(async () => {
   

        //CURRENTLY CONECTED TO TEST 
        const res = await fetch(process.env.REACT_APP_BOT_URL, { method: 'GET', mode : "cors" });

        const { token } = await res.json();
        setToken(token);
    }, []);

     

    //This defines the Redux store used to later set the state variable: store. Contains actions for Start conversations, receive messages and disconnecting the conversation
    const storeMiddleware = useCallback(({ dispatch }) => next => action => {
        //console.log(`ACTION SCHEMA: \n ${JSON.stringify(action, null, 2)}`);
        if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
          
            dispatch(
                {
                    type: 'WEB_CHAT/SEND_EVENT',
                    payload: {
                        name: 'startConversation',
                        type: 'event'
                    }
                }
            );
        }
        else if (action.type === 'DIRECT_LINE/POST_ACTIVITY') {
            action = updateIn(action, ['payload', 'activity', 'channelData', 'email'], () => 'johndoe@example.com');
            //console.log(`ACTION SCHEMA: \n ${JSON.stringify(action, null, 2)}`);
        }
        else if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
            console.log(`ACTION SCHEMA: \n ${JSON.stringify(action, null, 2)}`);
            const activity = action.payload.activity;

            if (activity.from.role === 'bot' && (activity.type === 'message' || activity.name === 'DynamicPlanFinished')) {

                setNewMessage(true);
   


                //if (!shouldIgnore(activity.text, false)) {
                //   // console.log(`Should ignore must be false\n${shouldIgnore(activity.text, false)}`);
                //    getLastMsgRef();
                //    setCanProvideFeedback(() => {
                //        const newFeedbackPermissions = [true, false, false];
                //        return newFeedbackPermissions;
                //    });
                //}

                if (activity.name === 'DynamicPlanFinished') {
                    getLastMsgRef();
                    setCanProvideFeedback(() => {
                        const newFeedbackPermissions = [true, false, false];
                        return newFeedbackPermissions;
                    });
                }

                storeBotResponses(activity.text);
        
            } else {
      
                setNewMessage(false);
                if (activity.type === 'message') {
                    storeUserMessages(activity.text);
                }
                if (activity.name === 'InputFeedback') {
                    disableSendbox();
                    acceptUserFeedback(activity.value);
                } else {
                    enableSendbox();
                    disableAllFeedback();
                }
                if (activity.name === 'confirmFeedbackLogged') {
                    displayConfirmationMsg(); 
                }
            }
        } else if (action.type === 'DIRECT_LINE/DISCONNECT_FULFILLED') {
            disableAllFeedback();
            setStore(null);
        }   

        return next(action);
    }, []);

    //State variables used for the minimize-maximize, switch sides and reset conversation effects
    const [loaded, setLoaded] = useState(false);
    const [minimized, setMinimized] = useState(true);
    const [newMessage, setNewMessage] = useState(false);
    const [side, setSide] = useState('right');
    const [token, setToken] = useState();
    const [botResponses, setBotResponses] = useState([]);
    const [userMessages, setUserMessages] = useState([]);
    const [directLine, setDirectLine] = useState();
    const [store, setStore] = useState(createStore({}, storeMiddleware));
    const [canProvideFeedback, setCanProvideFeedback] = useState([false, false, false]);
    const [userFeedback, setUserFeedback] = useState('');
    const [feedbackLogged, setFeedbackLogged] = useState(false);
    const [chatLanguage, setChatLanguage] = useState('En');
    const [showDisclaimer, setShowDisclaimer] = useState(false);
    //Make it an array of initial, goodfeedback, badfeedback
    const [lastMsgRef, setLastMsgRef] = useState();
    const [lastMsgToTranslateRef, setLastMsgToTranslateRef] = useState();

    //Sets the vh variable to calculate height when the chatbox is maximazed
    useEffect(() => {
        if (loaded) {
            setTimeout(() => {
                setVH();
                //styleSendBox();
            }, 500);
        }
        window.addEventListener('resize', setVH);
        
        return () => {
            window.removeEventListener('resize', setVH);
        };
    }, [token]);


    useEffect(() => {
        setTimeout(() => {
            setShowDisclaimer(true);
        }, 3000);
    }, []);

    //get last message reference and send a translate request
    const translateBotMsg = (text, chatLanguage) => {
        setLastMsgToTranslateRef(() => {
            console.log("setting new reference");
            const messagesList = document.querySelectorAll('.webchat__render-markdown p');
            const newRef = messagesList[messagesList.length - 1];
            return newRef;
        });

        if (lastMsgToTranslateRef) {
            console.log(lastMsgToTranslateRef);
            lastMsgToTranslateRef.textContent = translate(text, chatLanguage);
        } else {
            console.log("NO reference to last message yet");
        }

    }

    //We get the reference of the last bot message to place the feedback mechanism in
    const getLastMsgRef = () => {
        setTimeout(() => {
            setLastMsgRef(() => {
                const messagesList = document.querySelectorAll('.webchat__basic-transcript__activity-body');
                const newRef = messagesList[messagesList.length - 1];
                return newRef;
            });
        }, 1000);
    }
    //Sets the background transparent so the website can be seen behind the non-Chatbot-occupyied parts
    const styleSet = useMemo(
        () => createStyleSet({
            backgroundColor: 'Transparent'
        }),
        []
    );

    //Storing conversation messages

    const storeBotResponses = (newMsg) => {
        if (newMsg !== undefined) { 
            setBotResponses(prevResponses => {
                const updatedResponses = prevResponses.concat(newMsg);
                //console.log(updatedResponses);
                return updatedResponses;
            });
        }
    }
    const storeUserMessages = (newMsg) => {
        if (newMsg !== undefined && !shouldIgnore(newMsg, true)) {

            setUserMessages(prevMessages => {
                let updatedMessages = [...prevMessages];
                if (newMsg === "Domestic" || newMsg === "International") {
                    const lastMessage = updatedMessages[updatedMessages.length - 1];
                    const updatedLastMessage = `${lastMessage} (${newMsg})`;
                    updatedMessages.push(updatedLastMessage);
                } else {
                    updatedMessages = updatedMessages.concat(newMsg);
                }
                return updatedMessages;
            });
        }
    }

    // Event handler methods

    /**This handler resets the conversation completely and errases the messages*/
    const resetConversation = useCallback(() => {
        setToken(null);
        setDirectLine(null);
        store.dispatch(disconnect());
        setStore(createStore({}, storeMiddleware));
        handleFetchToken();
        setChatLanguage("En");
    }, [store]);

    const maximizeChatBox = useCallback(async () => {
        setLoaded(true);
        setMinimized(false);
        setNewMessage(false);
    }, []);

    const minimizeChatBox = useCallback(() => {
        setMinimized(true);
        setNewMessage(false);
    }, []);

    const switchChatBoxSide = useCallback(() => {
        setSide(side === 'left' ? 'right' : 'left');

    }, [side]);

    //FEEDBACK METHODS

    //Sends a user feedback event on thumbsup/down-button click
    const rateAnswer = useCallback((isGoodAnswer) => {
        store.dispatch({
            type: 'WEB_CHAT/SEND_EVENT',
            payload: {
                name: 'SendFeedback',
                type: 'event',
                value: `${isGoodAnswer? 'Good' : 'Bad'}:${botResponses[botResponses.length - 1]} USERQ${userMessages[userMessages.length - 1]}`
            }
        });
    });

    //Sets the conditions to render a component to accept user feedback comments
    const acceptUserFeedback = (isGood) => {
        if (isGood) {
            setCanProvideFeedback(() => {
                const newFeedbackPermissions = [false, true, false];
                return newFeedbackPermissions;
            });
        } else {
            setCanProvideFeedback(() => {
                const newFeedbackPermissions = [false, false, true];
                return newFeedbackPermissions;
            });
        }
    }
    const sendUserFeedback = (comment) => {
        setUserFeedback(comment);
        store.dispatch({
            type: 'WEB_CHAT/SEND_EVENT',
            payload: {
                name: 'SendFeedbackComment',
                type: 'event',
                value: comment
            }
        });
    }

    const disableAllFeedback = () => {
        setCanProvideFeedback(() => {
            const newFeedbackPermissions = [false, false, false];
            return newFeedbackPermissions;
        });
    }

    const displayConfirmationMsg = () => {
        setTimeout(() => {
            setFeedbackLogged(true);
        }, 5000);
        
        setTimeout(() => {
            setFeedbackLogged(false);
        }, 8000);
    }

    const updateLanguage = (newLang) => {
        setChatLanguage(newLang);
        changePlaceholder(newLang);
        store.dispatch({
            type: 'WEB_CHAT/SEND_EVENT',
            payload: {
                name: 'changeLanguage',
                type: 'event',
                value: newLang
            }
        });
    }
    const closeDisclaimer = () => {
        setShowDisclaimer(false);
    }

    return (
        <div className="minimizable-web-chat">
            {minimized && (
                <button className="maximize" onClick={maximizeChatBox} aria-label="Help" title="Help">
                    <div>
                    <span className={token ? 'ms-Icon ms-Icon--MessageFill' : 'ms-Icon ms-Icon--Message'} />
                    {newMessage && <span className="ms-Icon ms-Icon--CircleShapeSolid red-dot" />}
                        <span className="call-to-action">Help</span>
                    </div>
                </button>
            )}
            {loaded && (
                <div id="chatbox" className={classNames(side === 'left' ? 'chat-box left' : 'chat-box right', minimized ? 'hide' : '')}>
                    <ChatbotHeader
                        switchChatBoxSide={switchChatBoxSide}
                        minimizeChatBox={minimizeChatBox}
                    />
                    <Disclaimer
                        chatLanguage={chatLanguage}
                        showDisclaimer={showDisclaimer}
                        closeDisclaimer={closeDisclaimer}
                    />
                    <WebChat
                        className="react-web-chat"
                        onFetchToken={handleFetchToken}
                        store={store}
                        styleSet={styleSet}
                        token={token}
                        chatLanguage={chatLanguage}
                    />
                    {canProvideFeedback[0] &&
                        lastMsgRef &&
                        ReactDOM.createPortal(
                            <Feedback
                                chatLanguage={chatLanguage}
                                rateAnswer={rateAnswer}
                            />,
                            lastMsgRef
                        )
                    }
                    { feedbackLogged &&
                        lastMsgRef &&
                        ReactDOM.createPortal(
                            <ConfirmationMsg
                                userFeedback={userFeedback}
                                chatLanguage={chatLanguage} />,
                            lastMsgRef
                        )
                    }
                    {(canProvideFeedback[1] || canProvideFeedback[2]) &&
                        <GatherFeedback
                            chatLanguage={chatLanguage}
                            isGoodFeedback={canProvideFeedback}
                            sendUserFeedback={sendUserFeedback}
                        />
                    }
                    <Toolbar
                        chatLanguage={chatLanguage}
                        updateLanguage={updateLanguage}
                        resetConversation={resetConversation}
                    />
                    <ChatbotFooter
                        chatLanguage={chatLanguage} />
                </div>
            )}
        </div>
    );
};

export default MinimizableWebChat;


