import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { getFeedbackPromptTranslated } from '../../services/languages/translateUI'
import '../../assets/css/style.scss';

const Feedback = ({ rateAnswer, chatLanguage }) => {
    const promptText = getFeedbackPromptTranslated(chatLanguage);
    return (
        <div className="chatbot-feedback">
            <div className="feedback-filler">
            </div>
            <div className="feedback-container">
                <span>{promptText} </span>
                <div className="thumbs_up_down_container">
                    <button onClick={() => rateAnswer(true)} className="thumbs_up_down">
                        <FontAwesomeIcon icon={faThumbsUp} />
                    </button>
                    <button onClick={() => rateAnswer(false)} className="thumbs_up_down thumb_down">
                        <FontAwesomeIcon icon={faThumbsDown} />
                    </button>
                </div>
            </div>
            <div className="small-filler">
            </div>

        </div>
    );
};

export default Feedback;