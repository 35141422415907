import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';
import '../../assets/css/style.scss';

export const LangIcon = ({ chatLanguage, toggleLangSelector }) => {
    return (
        <button className="lang-icon" onClick={toggleLangSelector}>
            <FontAwesomeIcon icon={faLanguage} />
            <span>{chatLanguage}</span>
        </button>
    )
}