import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { getLangLabelsTranslated } from '../../services/languages/translateUI';
import { LangIcon } from './langIcon';
import { AllLanguageList } from './allLanguageList';
import WorldFlag from 'react-world-flags';
import '../../assets/css/style.scss';

export const LangSelector = ({ updateLanguage, chatLanguage }) => {
    const [displayLang, setDisplayLang] = useState(false);
    const [selectedlanguage, setSelectedlanguage] = useState('En');
    const [showAllLangs, setShowAllLangs] = useState(false);

    const langLabels = getLangLabelsTranslated(selectedlanguage);

    const toggleLangSelector = () => {
        setDisplayLang(!displayLang);
        setShowAllLangs(false);
    }
    const toggleAllLangList = () => {
        setShowAllLangs(!showAllLangs);
        setDisplayLang(!displayLang);
    }
    const closeAllLangList = () => {
        setShowAllLangs(false);
        setDisplayLang(false);
    }
    const changeLanguage = (event) => {
        let chosenlanguage = event.target.getAttribute('data-value');
        setSelectedlanguage(chosenlanguage);
        updateLanguage(chosenlanguage);
        setDisplayLang(false);
    }
    return (
        <>
            <LangIcon chatLanguage={chatLanguage} toggleLangSelector={toggleLangSelector} />
            {(displayLang || showAllLangs) && (<div className="dark-bg" onClick={closeAllLangList}></div>)}
            { showAllLangs && (
                <AllLanguageList closeList={toggleAllLangList} label={ langLabels[1]} />
            )}
            {displayLang && (
            
                <div className="lang-selector">
                    <ul className="lang-list">
                        <li onClick={changeLanguage} data-value="En">
                            <WorldFlag code="GB" className="flag-icon" />  English
                        </li>
                        <li onClick={changeLanguage} data-value="Fr">
                            <WorldFlag code="FR" className="flag-icon" />  Fran&ccedil;ais
                        </li>
                        <li onClick={changeLanguage} data-value="Es">
                            <WorldFlag code="ES" className="flag-icon" />  Espa&ntilde;ol
                        </li>
                    </ul>
                    <p className="all-lang-link" onClick={toggleAllLangList}>
                        {langLabels[0]}
                        <FontAwesomeIcon icon={faArrowRight} />
                    </p>
                </div>
            )}
        </>
    );
}