import _createForOfIteratorHelper from "@babel/runtime-corejs3/helpers/createForOfIteratorHelper";
import _typeof from "@babel/runtime-corejs3/helpers/typeof";
import _Object$create from "@babel/runtime-corejs3/core-js-stable/object/create";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyNames from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-names";
import _Object$getPrototypeOf from "@babel/runtime-corejs3/core-js-stable/object/get-prototype-of";
import _sliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/slice";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _someInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/some";
import _indexOfInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/index-of";
import _lastIndexOfInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/last-index-of";
import _spliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/splice";
import _Object$assign from "@babel/runtime-corejs3/core-js-stable/object/assign";
import _everyInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/every";
import _Object$keys2 from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Array$isArray from "@babel/runtime-corejs3/core-js-stable/array/is-array";
var __create = _Object$create;
var __defProp = _Object$defineProperty;
var __getOwnPropDesc = _Object$getOwnPropertyDescriptor;
var __getOwnPropNames = _Object$getOwnPropertyNames;
var __getProtoOf = _Object$getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __commonJS = function __commonJS(cb, mod) {
  return function __require() {
    return mod || (0, cb[__getOwnPropNames(cb)[0]])((mod = {
      exports: {}
    }).exports, mod), mod.exports;
  };
};
var __copyProps = function __copyProps(to, from, except, desc) {
  if (from && _typeof(from) === "object" || typeof from === "function") {
    var _iterator = _createForOfIteratorHelper(__getOwnPropNames(from)),
      _step;
    try {
      var _loop = function _loop() {
        var key = _step.value;
        if (!__hasOwnProp.call(to, key) && key !== except) __defProp(to, key, {
          get: function get() {
            return from[key];
          },
          enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable
        });
      };
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        _loop();
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  }
  return to;
};
var __toESM = function __toESM(mod, isNodeMode, target) {
  return target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", {
    value: mod,
    enumerable: true
  }) : target, mod);
};

// ../markdown-it-attrs/utils.js
var require_utils = __commonJS({
  "../markdown-it-attrs/utils.js": function _markdownItAttrs_utilsJs(exports) {
    exports.getAttrs = function (str, start, options) {
      var allowedKeyChars = /[^\t\n\f />"'=]/;
      var pairSeparator = " ";
      var keySeparator = "=";
      var classChar = ".";
      var idChar = "#";
      var attrs = [];
      var key = "";
      var value = "";
      var parsingKey = true;
      var valueInsideQuotes = false;
      for (var i = start + options.leftDelimiter.length; i < str.length; i++) {
        if (_sliceInstanceProperty(str).call(str, i, i + options.rightDelimiter.length) === options.rightDelimiter) {
          if (key !== "") {
            attrs.push([key, value]);
          }
          break;
        }
        var char_ = str.charAt(i);
        if (char_ === keySeparator && parsingKey) {
          parsingKey = false;
          continue;
        }
        if (char_ === classChar && key === "") {
          if (str.charAt(i + 1) === classChar) {
            key = "css-module";
            i += 1;
          } else {
            key = "class";
          }
          parsingKey = false;
          continue;
        }
        if (char_ === idChar && key === "") {
          key = "id";
          parsingKey = false;
          continue;
        }
        if (char_ === '"' && value === "" && !valueInsideQuotes) {
          valueInsideQuotes = true;
          continue;
        }
        if (char_ === '"' && valueInsideQuotes) {
          valueInsideQuotes = false;
          continue;
        }
        if (char_ === pairSeparator && !valueInsideQuotes) {
          if (key === "") {
            continue;
          }
          attrs.push([key, value]);
          key = "";
          value = "";
          parsingKey = true;
          continue;
        }
        if (parsingKey && char_.search(allowedKeyChars) === -1) {
          continue;
        }
        if (parsingKey) {
          key += char_;
          continue;
        }
        value += char_;
      }
      if (options.allowedAttributes && options.allowedAttributes.length) {
        var allowedAttributes = options.allowedAttributes;
        return _filterInstanceProperty(attrs).call(attrs, function (attrPair) {
          var attr = attrPair[0];
          function isAllowedAttribute(allowedAttribute) {
            return attr === allowedAttribute || allowedAttribute instanceof RegExp && allowedAttribute.test(attr);
          }
          return _someInstanceProperty(allowedAttributes).call(allowedAttributes, isAllowedAttribute);
        });
      }
      return attrs;
    };
    exports.addAttrs = function (attrs, token) {
      for (var j = 0, l = attrs.length; j < l; ++j) {
        var key = attrs[j][0];
        if (key === "class") {
          token.attrJoin("class", attrs[j][1]);
        } else if (key === "css-module") {
          token.attrJoin("css-module", attrs[j][1]);
        } else {
          token.attrPush(attrs[j]);
        }
      }
      return token;
    };
    exports.hasDelimiters = function (where, options) {
      if (!where) {
        throw new Error('Parameter `where` not passed. Should be "start", "end" or "only".');
      }
      return function (str) {
        var _context;
        var minCurlyLength = options.leftDelimiter.length + 1 + options.rightDelimiter.length;
        if (!str || typeof str !== "string" || str.length < minCurlyLength) {
          return false;
        }
        function validCurlyLength(curly) {
          var isClass = curly.charAt(options.leftDelimiter.length) === ".";
          var isId = curly.charAt(options.leftDelimiter.length) === "#";
          return isClass || isId ? curly.length >= minCurlyLength + 1 : curly.length >= minCurlyLength;
        }
        var start, end, slice, nextChar;
        var rightDelimiterMinimumShift = minCurlyLength - options.rightDelimiter.length;
        switch (where) {
          case "start":
            slice = _sliceInstanceProperty(str).call(str, 0, options.leftDelimiter.length);
            start = slice === options.leftDelimiter ? 0 : -1;
            end = start === -1 ? -1 : _indexOfInstanceProperty(str).call(str, options.rightDelimiter, rightDelimiterMinimumShift);
            nextChar = str.charAt(end + options.rightDelimiter.length);
            if (nextChar && _indexOfInstanceProperty(_context = options.rightDelimiter).call(_context, nextChar) !== -1) {
              end = -1;
            }
            break;
          case "end":
            start = _lastIndexOfInstanceProperty(str).call(str, options.leftDelimiter);
            end = start === -1 ? -1 : _indexOfInstanceProperty(str).call(str, options.rightDelimiter, start + rightDelimiterMinimumShift);
            end = end === str.length - options.rightDelimiter.length ? end : -1;
            break;
          case "only":
            slice = _sliceInstanceProperty(str).call(str, 0, options.leftDelimiter.length);
            start = slice === options.leftDelimiter ? 0 : -1;
            slice = _sliceInstanceProperty(str).call(str, str.length - options.rightDelimiter.length);
            end = slice === options.rightDelimiter ? str.length - options.rightDelimiter.length : -1;
            break;
          default:
            throw new Error("Unexpected case ".concat(where, ", expected 'start', 'end' or 'only'"));
        }
        return start !== -1 && end !== -1 && validCurlyLength(str.substring(start, end + options.rightDelimiter.length));
      };
    };
    exports.removeDelimiter = function (str, options) {
      var start = escapeRegExp(options.leftDelimiter);
      var end = escapeRegExp(options.rightDelimiter);
      var curly = new RegExp("[ \\n]?" + start + "[^" + start + end + "]+" + end + "$");
      var pos = str.search(curly);
      return pos !== -1 ? _sliceInstanceProperty(str).call(str, 0, pos) : str;
    };
    function escapeRegExp(s) {
      return s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
    }
    exports.escapeRegExp = escapeRegExp;
    exports.getMatchingOpeningToken = function (tokens, i) {
      if (tokens[i].type === "softbreak") {
        return false;
      }
      if (tokens[i].nesting === 0) {
        return tokens[i];
      }
      var level = tokens[i].level;
      var type = tokens[i].type.replace("_close", "_open");
      for (; i >= 0; --i) {
        if (tokens[i].type === type && tokens[i].level === level) {
          return tokens[i];
        }
      }
      return false;
    };
    var HTML_ESCAPE_TEST_RE = /[&<>"]/;
    var HTML_ESCAPE_REPLACE_RE = /[&<>"]/g;
    var HTML_REPLACEMENTS = {
      "&": "&amp;",
      "<": "&lt;",
      ">": "&gt;",
      '"': "&quot;"
    };
    function replaceUnsafeChar(ch) {
      return HTML_REPLACEMENTS[ch];
    }
    exports.escapeHtml = function (str) {
      if (HTML_ESCAPE_TEST_RE.test(str)) {
        return str.replace(HTML_ESCAPE_REPLACE_RE, replaceUnsafeChar);
      }
      return str;
    };
  }
});

// ../markdown-it-attrs/patterns.js
var require_patterns = __commonJS({
  "../markdown-it-attrs/patterns.js": function _markdownItAttrs_patternsJs(exports, module) {
    "use strict";

    var utils = require_utils();
    module.exports = function (options) {
      var __hr = new RegExp("^ {0,3}[-*_]{3,} ?" + utils.escapeRegExp(options.leftDelimiter) + "[^" + utils.escapeRegExp(options.rightDelimiter) + "]");
      return [{
        name: "fenced code blocks",
        tests: [{
          shift: 0,
          block: true,
          info: utils.hasDelimiters("end", options)
        }],
        transform: function transform(tokens, i) {
          var _context2;
          var token = tokens[i];
          var start = _lastIndexOfInstanceProperty(_context2 = token.info).call(_context2, options.leftDelimiter);
          var attrs = utils.getAttrs(token.info, start, options);
          utils.addAttrs(attrs, token);
          token.info = utils.removeDelimiter(token.info, options);
        }
      }, {
        name: "inline nesting 0",
        tests: [{
          shift: 0,
          type: "inline",
          children: [{
            shift: -1,
            type: function type(str) {
              return str === "image" || str === "code_inline";
            }
          }, {
            shift: 0,
            type: "text",
            content: utils.hasDelimiters("start", options)
          }]
        }],
        transform: function transform(tokens, i, j) {
          var _context3;
          var token = tokens[i].children[j];
          var endChar = _indexOfInstanceProperty(_context3 = token.content).call(_context3, options.rightDelimiter);
          var attrToken = tokens[i].children[j - 1];
          var attrs = utils.getAttrs(token.content, 0, options);
          utils.addAttrs(attrs, attrToken);
          if (token.content.length === endChar + options.rightDelimiter.length) {
            var _context4;
            _spliceInstanceProperty(_context4 = tokens[i].children).call(_context4, j, 1);
          } else {
            var _context5;
            token.content = _sliceInstanceProperty(_context5 = token.content).call(_context5, endChar + options.rightDelimiter.length);
          }
        }
      }, {
        name: "tables",
        tests: [{
          shift: 0,
          type: "table_close"
        }, {
          shift: 1,
          type: "paragraph_open"
        }, {
          shift: 2,
          type: "inline",
          content: utils.hasDelimiters("only", options)
        }],
        transform: function transform(tokens, i) {
          var token = tokens[i + 2];
          var tableOpen = utils.getMatchingOpeningToken(tokens, i);
          var attrs = utils.getAttrs(token.content, 0, options);
          utils.addAttrs(attrs, tableOpen);
          _spliceInstanceProperty(tokens).call(tokens, i + 1, 3);
        }
      }, {
        name: "inline attributes",
        tests: [{
          shift: 0,
          type: "inline",
          children: [{
            shift: -1,
            nesting: -1
          }, {
            shift: 0,
            type: "text",
            content: utils.hasDelimiters("start", options)
          }]
        }],
        transform: function transform(tokens, i, j) {
          var token = tokens[i].children[j];
          var content = token.content;
          var attrs = utils.getAttrs(content, 0, options);
          var openingToken = utils.getMatchingOpeningToken(tokens[i].children, j - 1);
          utils.addAttrs(attrs, openingToken);
          token.content = _sliceInstanceProperty(content).call(content, _indexOfInstanceProperty(content).call(content, options.rightDelimiter) + options.rightDelimiter.length);
        }
      }, {
        name: "list softbreak",
        tests: [{
          shift: -2,
          type: "list_item_open"
        }, {
          shift: 0,
          type: "inline",
          children: [{
            position: -2,
            type: "softbreak"
          }, {
            position: -1,
            type: "text",
            content: utils.hasDelimiters("only", options)
          }]
        }],
        transform: function transform(tokens, i, j) {
          var _context6;
          var token = tokens[i].children[j];
          var content = token.content;
          var attrs = utils.getAttrs(content, 0, options);
          var ii = i - 2;
          while (tokens[ii - 1] && tokens[ii - 1].type !== "ordered_list_open" && tokens[ii - 1].type !== "bullet_list_open") {
            ii--;
          }
          utils.addAttrs(attrs, tokens[ii - 1]);
          tokens[i].children = _sliceInstanceProperty(_context6 = tokens[i].children).call(_context6, 0, -2);
        }
      }, {
        name: "list double softbreak",
        tests: [{
          shift: 0,
          type: function type(str) {
            return str === "bullet_list_close" || str === "ordered_list_close";
          }
        }, {
          shift: 1,
          type: "paragraph_open"
        }, {
          shift: 2,
          type: "inline",
          content: utils.hasDelimiters("only", options),
          children: function children(arr) {
            return arr.length === 1;
          }
        }, {
          shift: 3,
          type: "paragraph_close"
        }],
        transform: function transform(tokens, i) {
          var token = tokens[i + 2];
          var content = token.content;
          var attrs = utils.getAttrs(content, 0, options);
          var openingToken = utils.getMatchingOpeningToken(tokens, i);
          utils.addAttrs(attrs, openingToken);
          _spliceInstanceProperty(tokens).call(tokens, i + 1, 3);
        }
      }, {
        name: "list item end",
        tests: [{
          shift: -2,
          type: "list_item_open"
        }, {
          shift: 0,
          type: "inline",
          children: [{
            position: -1,
            type: "text",
            content: utils.hasDelimiters("end", options)
          }]
        }],
        transform: function transform(tokens, i, j) {
          var token = tokens[i].children[j];
          var content = token.content;
          var attrs = utils.getAttrs(content, _lastIndexOfInstanceProperty(content).call(content, options.leftDelimiter), options);
          utils.addAttrs(attrs, tokens[i - 2]);
          var trimmed = _sliceInstanceProperty(content).call(content, 0, _lastIndexOfInstanceProperty(content).call(content, options.leftDelimiter));
          token.content = last(trimmed) !== " " ? trimmed : _sliceInstanceProperty(trimmed).call(trimmed, 0, -1);
        }
      }, {
        name: "\n{.a} softbreak then curly in start",
        tests: [{
          shift: 0,
          type: "inline",
          children: [{
            position: -2,
            type: "softbreak"
          }, {
            position: -1,
            type: "text",
            content: utils.hasDelimiters("only", options)
          }]
        }],
        transform: function transform(tokens, i, j) {
          var _context7;
          var token = tokens[i].children[j];
          var attrs = utils.getAttrs(token.content, 0, options);
          var ii = i + 1;
          while (tokens[ii + 1] && tokens[ii + 1].nesting === -1) {
            ii++;
          }
          var openingToken = utils.getMatchingOpeningToken(tokens, ii);
          utils.addAttrs(attrs, openingToken);
          tokens[i].children = _sliceInstanceProperty(_context7 = tokens[i].children).call(_context7, 0, -2);
        }
      }, {
        name: "horizontal rule",
        tests: [{
          shift: 0,
          type: "paragraph_open"
        }, {
          shift: 1,
          type: "inline",
          children: function children(arr) {
            return arr.length === 1;
          },
          content: function content(str) {
            return str.match(__hr) !== null;
          }
        }, {
          shift: 2,
          type: "paragraph_close"
        }],
        transform: function transform(tokens, i) {
          var token = tokens[i];
          token.type = "hr";
          token.tag = "hr";
          token.nesting = 0;
          var content = tokens[i + 1].content;
          var start = _lastIndexOfInstanceProperty(content).call(content, options.leftDelimiter);
          var attrs = utils.getAttrs(content, start, options);
          utils.addAttrs(attrs, token);
          token.markup = content;
          _spliceInstanceProperty(tokens).call(tokens, i + 1, 2);
        }
      }, {
        name: "end of block",
        tests: [{
          shift: 0,
          type: "inline",
          children: [{
            position: -1,
            content: utils.hasDelimiters("end", options),
            type: function type(t) {
              return t !== "code_inline" && t !== "math_inline";
            }
          }]
        }],
        transform: function transform(tokens, i, j) {
          var token = tokens[i].children[j];
          var content = token.content;
          var attrs = utils.getAttrs(content, _lastIndexOfInstanceProperty(content).call(content, options.leftDelimiter), options);
          var ii = i + 1;
          while (tokens[ii + 1] && tokens[ii + 1].nesting === -1) {
            ii++;
          }
          var openingToken = utils.getMatchingOpeningToken(tokens, ii);
          utils.addAttrs(attrs, openingToken);
          var trimmed = _sliceInstanceProperty(content).call(content, 0, _lastIndexOfInstanceProperty(content).call(content, options.leftDelimiter));
          token.content = last(trimmed) !== " " ? trimmed : _sliceInstanceProperty(trimmed).call(trimmed, 0, -1);
        }
      }];
    };
    function last(arr) {
      return _sliceInstanceProperty(arr).call(arr, -1)[0];
    }
  }
});

// ../markdown-it-attrs/index.js
var require_markdown_it_attrs = __commonJS({
  "../markdown-it-attrs/index.js": function _markdownItAttrs_indexJs(exports, module) {
    "use strict";

    var patternsConfig = require_patterns();
    var defaultOptions = {
      leftDelimiter: "{",
      rightDelimiter: "}",
      allowedAttributes: []
    };
    module.exports = function attributes(md, options_) {
      var options = _Object$assign({}, defaultOptions);
      options = _Object$assign(options, options_);
      var patterns = patternsConfig(options);
      function curlyAttrs(state) {
        var tokens = state.tokens;
        var _loop2 = function _loop2(i) {
          var _loop3 = function _loop3(_p) {
            var _context8;
            var pattern = patterns[_p];
            var j = null;
            var match = _everyInstanceProperty(_context8 = pattern.tests).call(_context8, function (t) {
              var res = test(tokens, i, t);
              if (res.j !== null) {
                j = res.j;
              }
              return res.match;
            });
            if (match) {
              pattern.transform(tokens, i, j);
              if (pattern.name === "inline attributes" || pattern.name === "inline nesting 0") {
                _p--;
              }
            }
            p = _p;
          };
          for (var p = 0; p < patterns.length; p++) {
            _loop3(p);
          }
        };
        for (var i = 0; i < tokens.length; i++) {
          _loop2(i);
        }
      }
      md.core.ruler.before("linkify", "curly_attributes", curlyAttrs);
    };
    function test(tokens, i, t) {
      var res = {
        match: false,
        j: null
      };
      var ii = t.shift !== void 0 ? i + t.shift : t.position;
      if (t.shift !== void 0 && ii < 0) {
        return res;
      }
      var token = get(tokens, ii);
      if (token === void 0) {
        return res;
      }
      var _loop4 = function _loop4() {
          var key = _Object$keys[_i];
          if (key === "shift" || key === "position") {
            return 0; // continue
          }
          if (token[key] === void 0) {
            return {
              v: res
            };
          }
          if (key === "children" && isArrayOfObjects(t.children)) {
            if (token.children.length === 0) {
              return {
                v: res
              };
            }
            var match;
            var childTests = t.children;
            var children = token.children;
            if (_everyInstanceProperty(childTests).call(childTests, function (tt) {
              return tt.position !== void 0;
            })) {
              match = _everyInstanceProperty(childTests).call(childTests, function (tt) {
                return test(children, tt.position, tt).match;
              });
              if (match) {
                var j = last(childTests).position;
                res.j = j >= 0 ? j : children.length + j;
              }
            } else {
              var _loop5 = function _loop5(_j) {
                match = _everyInstanceProperty(childTests).call(childTests, function (tt) {
                  return test(children, _j, tt).match;
                });
                if (match) {
                  res.j = _j;
                  return 1; // break
                }
              };
              for (var _j = 0; _j < children.length; _j++) {
                if (_loop5(_j)) break;
              }
            }
            if (match === false) {
              return {
                v: res
              };
            }
            return 0; // continue
          }
          switch (_typeof(t[key])) {
            case "boolean":
            case "number":
            case "string":
              if (token[key] !== t[key]) {
                return {
                  v: res
                };
              }
              break;
            case "function":
              if (!t[key](token[key])) {
                return {
                  v: res
                };
              }
              break;
            case "object":
              if (isArrayOfFunctions(t[key])) {
                var _context9;
                var r = _everyInstanceProperty(_context9 = t[key]).call(_context9, function (tt) {
                  return tt(token[key]);
                });
                if (r === false) {
                  return {
                    v: res
                  };
                }
                break;
              }
            default:
              throw new Error("Unknown type of pattern test (key: ".concat(key, "). Test should be of type boolean, number, string, function or array of functions."));
          }
        },
        _ret;
      for (var _i = 0, _Object$keys = _Object$keys2(t); _i < _Object$keys.length; _i++) {
        _ret = _loop4();
        if (_ret === 0) continue;
        if (_ret) return _ret.v;
      }
      res.match = true;
      return res;
    }
    function isArrayOfObjects(arr) {
      return _Array$isArray(arr) && arr.length && _everyInstanceProperty(arr).call(arr, function (i) {
        return _typeof(i) === "object";
      });
    }
    function isArrayOfFunctions(arr) {
      return _Array$isArray(arr) && arr.length && _everyInstanceProperty(arr).call(arr, function (i) {
        return typeof i === "function";
      });
    }
    function get(arr, n) {
      return n >= 0 ? arr[n] : arr[arr.length + n];
    }
    function last(arr) {
      return _sliceInstanceProperty(arr).call(arr, -1)[0] || {};
    }
  }
});

// src/index.mjs
var import_markdown_it_attrs = __toESM(require_markdown_it_attrs(), 1);
var src_default = import_markdown_it_attrs["default"];
export { src_default as default };
