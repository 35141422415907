import React from 'react';
import MinimizableWebChat from './components/MinimizableWebChat';

const App = () => (
  <div className="App">
    <MinimizableWebChat />
  </div>
);

export default App;
