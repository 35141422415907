import React, { useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { AllLanguageLabel } from './allLangLabel';
import WorldFlag from 'react-world-flags';
import '../../assets/css/style.scss';

export const AllLanguageList = ({ closeList, label }) => {
    const [showLabel, setShowLabel] = useState(false);
    const toggleLabel = () => {
        setShowLabel(!showLabel);
    }
    return (
        <div className="all-lang-container">
            {showLabel && (
                <AllLanguageLabel label={label} />
            )}
            <div className="all-lang-header">
                <FontAwesomeIcon icon={faArrowLeft} className="all-lang-button" onClick={closeList} />
                <FontAwesomeIcon icon={faCircleInfo} className="all-lang-button"
                    onMouseEnter={toggleLabel}  
                    onMouseLeave={toggleLabel}  
                />
            </div>
            
            <ul className="all-lang-list">
                <li>
                    <WorldFlag code="CN" className="flag-icon" />  Chinese
                </li>
                <li>
                    <WorldFlag code="CZ" className="flag-icon" />  Czech
                </li>
                <li>
                    <WorldFlag code="DK" className="flag-icon" />  Danish
                </li>
                <li>
                    <WorldFlag code="NL" className="flag-icon" />  Dutch
                </li>
                <li>
                    <WorldFlag code="FI" className="flag-icon" />  Finnish
                </li>
                <li>
                    <WorldFlag code="DE" className="flag-icon" />  German
                </li>
                <li>
                    <WorldFlag code="GR" className="flag-icon" />  Greek
                </li>
                <li>
                    <WorldFlag code="IN" className="flag-icon" />  Hindi
                </li>
                <li>
                    <WorldFlag code="ID" className="flag-icon" />  Indonesian
                </li>
                <li>
                    <WorldFlag code="IT" className="flag-icon" />  Italian
                </li>
                <li>
                    <WorldFlag code="JP" className="flag-icon" />  Japanese
                </li>
                <li>
                    <WorldFlag code="KR" className="flag-icon" />  Korean
                </li>
                <li>
                    <WorldFlag code="NO" className="flag-icon" />  Norwegian
                </li>
                <li>
                    <WorldFlag code="PL" className="flag-icon" />  Polish
                </li>
                <li>
                    <WorldFlag code="BR" className="flag-icon" />  Portuguese
                </li>
                <li>
                    <WorldFlag code="RU" className="flag-icon" />  Russian
                </li>
                <li>
                    <WorldFlag code="SE" className="flag-icon" />  Swedish
                </li>
                <li>
                    <WorldFlag code="TH" className="flag-icon" />  Thai
                </li>
                <li>
                    <WorldFlag code="TR" className="flag-icon" />  Turkish
                </li>
            </ul>
        </div>
    );
}